import Permissions from "@/Client/Mixin/Permissions";
import _ from "lodash";

export default class Navigation {
  items = [
    {
      label: 'Create',
      items: [
        {
          group: 'Content',
          options: [
            {
              label: 'Movie',
              link: {name: 'createMovie'},
              requiredPermission: 'ROLE_CONTENT_MOVIE_RESOURCE_CREATE',
            },
            {
              label: 'TV Show',
              link: {name: 'createTelevision'},
              requiredPermission: 'ROLE_CONTENT_TELEVISION_RESOURCE_CREATE',
            },
            {
              label: 'TV Short',
              link: {name: 'createShort'},
              requiredPermission: 'ROLE_CONTENT_SHORT_RESOURCE_CREATE',
            },
            {
              label: 'Album',
              link: {name: 'createAlbum'},
              requiredPermission: 'ROLE_CONTENT_ALBUM_RESOURCE_CREATE',
               excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Other Video',
              link: {name: 'createOtherVideoItem'},
              requiredPermission: 'ROLE_CONTENT_OTHER_VIDEO_ITEM_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },            {
              label: 'Other Audio',
              link: {name: 'createOtherAudioItem'},
              requiredPermission: 'ROLE_CONTENT_OTHER_AUDIO_ITEM_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Other Audio Group',
              link: {name: 'createOtherAudioGroup'},
              requiredPermission: 'ROLE_CONTENT_OTHER_AUDIO_GROUP_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
        {
          group: 'Support',
          options: [
            {
              label: 'Category',
              link: {name: 'createCategory'},
              requiredPermission: 'ROLE_CATEGORY_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Deal',
              link: {name: 'createDeal'},
              requiredPermission: 'ROLE_DEAL_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Genre',
              link: {name: 'createGenre'},
              requiredPermission: 'ROLE_GENRE_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Language',
              link: {name: 'createLanguage'},
              requiredPermission: 'ROLE_LANGUAGE_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Rating',
              link: {name: 'createRating'},
              requiredPermission: 'ROLE_RATING_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Territory',
              link: {name: 'createTerritory'},
              requiredPermission: 'ROLE_TERRITORY_TERRITORY_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Version Type',
              link: {name: 'createVersionType'},
              requiredPermission: 'ROLE_VERSIONTYPE_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
        {
          group: 'Selection',
          options: [
            {
              label: 'Selection',
              link: {name: 'createSelection'},
              requiredPermission: 'ROLE_CONTENTSELECTION_RESOURCE_CREATE'
            },
            {
              label: 'Draft Selection',
              link: {name: 'createDraftSelection'},
              requiredPermission: 'ROLE_DRAFTSELECTION_USER'
            }
          ]
        },
        {
          group: 'Talent',
          options: [
            {
              label: 'Actor/Directors',
              link: {name: 'createActorDirector'},
              requiredPermission: 'ROLE_ADMIN'
            }
          ]
        },
        {
          group: 'People',
          options: [
            {
              label: 'Contact',
              link: {name: 'createContact'},
              requiredPermission: 'ROLE_ADMIN'
            },
            {
              label: 'Company',
              link: {name: 'createCompany'},
              requiredPermission: 'ROLE_COMPANY_ADMIN'
            },
          ]
        },
        {
          group: 'Order',
          options: [
            {
              label: 'Invoice',
              link: {name: 'createInvoice'},
              requiredPermission: 'ROLE_INVOICE_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
        {
          group: 'Export',
          options: [
            {
              label: 'Export',
              link: {name: 'createExport'},
              requiredPermission: 'ROLE_EXPORT_RESOURCE_CREATE',
            }
          ],
        },
        {
          group: 'Import',
          options: [
            {
              label: 'Import',
              link: {name: 'createImport'},
              requiredPermission: 'ROLE_IMPORT_RESOURCE_CREATE',
            }
          ],
        },
      ],
    },
    {
      label: 'Discover',
      items: [
        {
          group: 'Content',
          options: [
            {
              label: 'Availability',
              link: {name: 'availabilityList'},
              requiredPermission: 'ROLE_AVAILABILITY_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Distributors',
              link: {name: 'distributorProfile'},
              requiredPermission: 'ROLE_DISTRIBUTOR_RESOURCE_READ',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Airline Portal',
              link: {name: 'airlineClientPortal'},
              requiredPermission: 'ROLE_SELECTION_USER'
            },
            {
              additionalChecks: (getters) => {
                return getters.user.isDistributor;
              },
              label: 'Showcase',
              link: {
                name: 'distributorLanding',
              },
              params: (getters) => {
                return {
                  id: getters.user.company.split('/').slice(-1)[0],
                };
              },
              requiredPermission: 'ROLE_DISTRIBUTOR_USER',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Movies',
              link: {name: 'movieList'},
              requiredPermission: 'ROLE_CONTENT_MOVIE_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'TV',
              link: {name: 'televisionList'},
              requiredPermission: 'ROLE_CONTENT_TELEVISION_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Albums',
              link: {name: 'albumList'},
              requiredPermission: 'ROLE_CONTENT_ALBUM_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Other Videos',
              link: {name: 'otherVideoItemList'},
              requiredPermission: 'ROLE_CONTENT_OTHER_VIDEO_ITEM_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Other Audio',
              link: {name: 'otherAudioItemList'},
              requiredPermission: 'ROLE_CONTENT_OTHER_AUDIO_ITEM_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Other Audio Groups',
              link: {name: 'otherAudioGroupList'},
              requiredPermission: 'ROLE_CONTENT_OTHER_AUDIO_GROUP_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ],
        },
      ]
    },
    {
      label: 'My Content',
      items: [
        {
          group: 'Selection',
          options: [
            {
              label: 'Selections',
              link: {name: 'statusSheet'},
              requiredPermission: 'ROLE_SELECTION_USER'
            },
            {
              label: 'Selection Metadata',
              link: {name: 'updateSelectionMetadata'},
              requiredPermission: 'ROLE_SELECTION_RESOURCE_LIST'
            },
            {
              label: 'Selection Categories',
              link: {name: 'updateSelectionCategories'},
              requiredPermission: 'ROLE_SELECTION_RESOURCE_LIST',
            },
            {
              label: 'Selection Category Order',
              link: {name: 'updateSelectionCategoryOrdering'},
              requiredPermission: 'ROLE_SELECTION_RESOURCE_LIST'
            },
            {
              label: 'Selection Images',
              link: {name: 'updateCycleSelectionImageConfigs'},
              requiredPermission: 'ROLE_SELECTION_RESOURCE_LIST',
            }
          ],
        },
        {
          group: 'Load Sheet',
          options: [
            {
              label: 'Load Sheet',
              link: {name: 'loadSheet'},
              requiredPermission: 'ROLE_ORDERS_READ_PARTIAL',
            },
          ],
        },
        {
          group: 'Order',
          options: [
            {
              label: 'License Orders',
              link: {name: 'licenseOrderList', query: {page: 1}},
              requiredPermission: 'ROLE_LICENSEORDER_RESOURCE_LIST'
            },
            {
              label: 'Material Orders',
              link: {name: 'materialOrderList', query: {page: 1}},
              requiredPermission: 'ROLE_MATERIALORDER_RESOURCE_LIST'
            },
            {
              label: 'Invoices',
              link: {name: 'invoiceList', query: {page: 1}},
              requiredPermission: 'ROLE_INVOICE_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
        {
          group: 'Lab',
          options: [
            {
              label: 'Materials Checklist',
              link: {name: 'materialsList', query: {page: 1}},
              requiredPermission: 'ROLE_MATERIALORDER_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Deliverables List',
              link: {name: 'deliverablesChecklist', query: {page: 1}},
              requiredPermission: 'ROLE_MATERIALORDER_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
        {
          group: 'Documents',
          options: [
            {
              label: 'Exports',
              link: {name: 'exportList'},
              requiredPermission: 'ROLE_EXPORT_USER'
            },
          ]
        },
      ]
    },
    {
      label: 'Manage',
      items: [
        {
          group: 'Support',
          options: [
            {
              label: 'Deals',
              link: {name: 'dealList'},
              requiredPermission: 'ROLE_DEAL_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Cycles',
              link: {name: 'cyclesList'},
              requiredPermission: 'ROLE_ADMIN',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Categories',
              link: {name: 'categoryList'},
              requiredPermission: 'ROLE_CATEGORY_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Tags',
              link: {name: 'tagList'},
              requiredPermission: 'ROLE_TAG_RESOURCE_LIST',
              excludeRoles: ['ROLE_DISTRIBUTOR_MOVIE_USER', 'ROLE_DISTRIBUTOR_TV_USER', 'ROLE_DEMO_USER'],
            },
            {
              label: 'Import Aliases',
              link: {name: 'listCycleSelectionAliases'},
              requiredPermission: 'ROLE_IMPORT_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
        {
          group: 'Talent',
          options: [
            {
              label: 'Actors',
              link: {name: 'actorList'},
              requiredPermission: 'ROLE_ADMIN'
            },
            {
              label: 'Directors',
              link: {name: 'directorList'},
              requiredPermission: 'ROLE_ADMIN'
            },
          ]
        },
        {
          group: 'People',
          options: [
            {
              label: 'Contacts',
              link: {name: 'contactList'},
              requiredPermission: 'ROLE_ADMIN'
            },
            {
              label: 'Companies',
              link: {name: 'companyList'},
              requiredPermission: 'ROLE_COMPANY_ADMIN'
            },
            {
              label: 'My Airlines',
              link: {name: 'cspAirlineList'},
              requiredPermission: 'ROLE_CSP_USER'
            },
          ]
        },
        {
          group: 'Showcase',
          options: [
            {
              label: 'Showcase Users',
              link: { name: 'showcaseUsers' },
              requiredPermission: 'ROLE_COMPANY_USER_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Access Requests',
              link: { name: 'accessRequests' },
              requiredPermission: 'ROLE_COMPANY_USER_REQUEST_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
      ]
    },
    {
      label: 'Reports',
      items: [
        {
          group: 'Reports',
          options: [
            {
              label: 'Viewing Statistics',
              link: { name: 'viewingStatistics' },
              requiredPermission: 'ROLE_VIEWING_STATISTICS_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Studio Performance',
              link: { name: 'studioPerformanceReport' },
              requiredPermission: 'ROLE_ADMIN',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Cycle',
              link: { name: 'cycleReport' },
              requiredPermission: 'ROLE_SELECTION_USER'
            },
            {
              label: 'Distributor',
              link: { name: 'distributorReport' },
              requiredPermission: 'ROLE_SELECTION_USER',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
      ]
    },
    {
      label: 'System',
      items: [
        {
          group: 'Lists',
          label: 'Lists',
          options: [
            {
              label: 'System Tasks',
              link: {name: 'systemTaskList'},
              requiredPermission: 'ROLE_SYSTEM_TASK_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'System Task Executions',
              link: {name: 'systemTaskExecutionList'},
              requiredPermission: 'ROLE_SYSTEM_TASK_EXECUTION_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Genres',
              link: {name: 'genreList'},
              requiredPermission: 'ROLE_GENRE_RESOURCE_LIST',
              excludeRoles: ['ROLE_DISTRIBUTOR_MOVIE_USER', 'ROLE_DISTRIBUTOR_TV_USER', 'ROLE_DEMO_USER'],
            },
            {
              label: 'Languages',
              link: {name: 'languageList'},
              requiredPermission: 'ROLE_LANGUAGE_RESOURCE_LIST',
              excludeRoles: ['ROLE_DISTRIBUTOR_MOVIE_USER', 'ROLE_DISTRIBUTOR_TV_USER', 'ROLE_DEMO_USER'],
            },
            {
              label: 'Territories',
              link: {name: 'territoryList'},
              requiredPermission: 'ROLE_TERRITORY_TERRITORY_RESOURCE_LIST',
              excludeRoles: ['ROLE_DISTRIBUTOR_MOVIE_USER', 'ROLE_DISTRIBUTOR_TV_USER', 'ROLE_DEMO_USER'],
            },
            {
              label: 'Version Types',
              link: {name: 'versionTypeList'},
              requiredPermission: 'ROLE_VERSIONTYPE_RESOURCE_LIST',
              excludeRoles: ['ROLE_DISTRIBUTOR_MOVIE_USER', 'ROLE_DISTRIBUTOR_TV_USER', 'ROLE_DEMO_USER'],
            },
            {
              label: 'Ratings',
              link: {name: 'ratingList'},
              requiredPermission: 'ROLE_RATING_RESOURCE_LIST',
              excludeRoles: ['ROLE_DISTRIBUTOR_MOVIE_USER', 'ROLE_DISTRIBUTOR_TV_USER', 'ROLE_DEMO_USER'],
            },
            {
              label: 'Systems',
              link: { name: 'systemList' },
              requiredPermission: 'ROLE_SYSTEM_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Data Options',
              link: { name: 'dataOptions' },
              requiredPermission: 'ROLE_DATAOPTION_DATAOPTION_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Common State Change Reasons',
              link: { name: 'commonStateChangeReasons' },
              requiredPermission: 'ROLE_TEXTVALUE_COMMONSTATECHANGEREASON_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Users',
              link: { name: 'users' },
              requiredPermission: 'ROLE_USER_RESOURCE_CREATE',
              excludeRoles: ['ROLE_DEMO_USER']
            },
            {
              label: 'Login Attempts',
              link: { name: 'loginAttempts' },
              requiredPermission: 'ROLE_LOGINATTEMPT_RESOURCE_LIST',
              excludeRoles: ['ROLE_DEMO_USER']
            },
          ]
        },
        {
          group: 'Create',
          label: 'Create New',
          options: [
            {
              label: 'Create System',
              link: { name: 'createSystem' },
              requiredPermission: 'ROLE_SYSTEM_RESOURCE_CREATE'
            }
          ],
        },
      ]
    }
  ];

  /**
   * Perform additional checks as to whether we can show this link in the navigation. Additional checks can be done by
   * using the VueX getters
   *
   * If there's no additional check defined, then the additional checks pass without further evaluation
   *
   * @param option The specific navigation option above
   * @param getters The complete set of VueX getters
   * @returns boolean whether the additional check criteria passes
   */
  additionalChecks(option, getters) {
    if ('function' !== typeof option.additionalChecks) {
      return true;
    }

    return Boolean(option.additionalChecks(getters));
  }

  /**
   * Returns the navigation options based on a user
   * @param {User} user
   * @param getters The VueX getters for doing additional checks whether to show an option in the navigation
   */
  getUserNavOptions(user, getters) {
    return this.filterOptionsByUser(_.cloneDeep(this.items), user, getters);
  }

  filterOptionsByUser(nav, user, getters) {
    return nav.filter((topLevel) => {
      topLevel.items = topLevel.items.filter((group) => {
        group.options = group.options.filter((option) => {
          if ('undefined' !== typeof option.excludeRoles) {
            for (const role of option.excludeRoles) {
              if (Permissions.methods.hasPermission(role, user)) {
                return false;
              }
            }
          }

          return Permissions.methods.hasPermission(option.requiredPermission, user) &&
              this.additionalChecks(option, getters);
        })
        return group.options.length > 0;
      })
      return topLevel.items.length > 0
    });
  }
}
