export default {
  state: {
    status: '',
    languages: [],
  },
  mutations: {
    lang_request(state) {
      state.status = 'loading';
    },
    lang_success(state, languages) {
      state.status = 'success';
      state.languages = languages;
    },
    lang_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setLanguages({commit}, data) {
      commit('lang_success', data);
    },
  },
  getters: {
    availableLanguages: (state) => {
      const languages = state.languages;
      const eng = languages.find((language) => language.code === 'ENG');
      const frp = languages.find((language) => language.code === 'FRP');
      const jpn = languages.find((language) => language.code === 'JPN');
      const div = {
        'id': '',
        '_id': '',
        'code': '-',
        'name': '-----',
        'disabled': true,
      };

      languages.unshift(...[eng, frp, jpn, div]);

      return languages;
    },
  },
};
