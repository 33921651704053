<template>
  <b-nav-item-dropdown
    right
    :disabled="0 === notifications.length"
    @show="viewNotifications"
  >
    <template #button-content>
      <b-icon-bell-fill />
      <b-badge
        v-if="0 !== unreadNotifications"
        pill
        variant="danger"
        style="position: absolute; top: 2px; left: 15px;"
      >
        {{ unreadNotifications }}
      </b-badge>
    </template>

    <b-dropdown-item
      v-for="(notification, index) in sortedNotifications"
      :key="index"
      :variant="notification.successful ? '' : 'danger'"
      @click="goTo(notification)"
    >
      <div>{{ notification.message }}</div>
      <div
        class="text-right text-muted"
        style="font-size: 10pt;"
      >
        {{ notification.user }}, {{ notification.time|formatDateTime }}
      </div>
      <b-dropdown-divider />
    </b-dropdown-item>
    <b-dropdown-item
      class="text-right"
      @click="clearNotifications"
    >
      Clear
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import UserPreferences from '@/Client/Model/UserPreferences';
import moment from 'moment';

export default {
  name: 'NotificationMenu',
  data() {
    return {
      intervalId: null,
      notifications: [],
      unreadNotifications: 0,
      lastNotificationTime: null,
    };
  },
  computed: {
    sortedNotifications() {
      return [...this.notifications].sort((a, b) => {
        return 0 - a.time.localeCompare(b.time);
      });
    },
  },
  mounted() {
    this.lastNotificationTime = moment.utc().subtract('7', 'days');

    this.getNotifications();

    this.intervalId = setInterval(() => {
      this.getNotifications();
    }, 60000);
  },
  methods: {
    clearNotifications() {
      this.notifications = [];
      this.unreadNotifications = 0;
    },
    getNotifications() {
      // Users who don't have permission to be here can do one
      if (
        !this.hasPermission('ROLE_NAVIGATION_USER') ||
        !this.hasPermission('ROLE_NOTIFICATION_RESOURCE_LIST')
      ) {
        return;
      }

      this.axios.get(
          `/notifications`,
          {
            params: {
              'from': this.lastNotificationTime.format('YYYY-MM-DD HH:mm:ss'),
            },
          },
      ).then((resp) => {
        const newNotifications = resp.data.notifications ?? [];

        for (const newNotification of newNotifications) {
          this.notifications.push({
            time: newNotification.date,
            message: newNotification.message,
            user: newNotification.user,
            successful: newNotification.successful ?? true,
            iri: newNotification.iri ?? null,
          });
        }

        const unread = newNotifications.filter((notification) => {
          return moment(notification.date).utc().unix() >
            Number.parseInt(UserPreferences.getMostRecentNotification() ?? 0);
        });

        if (0 !== unread) {
          this.unreadNotifications += unread.length;
        }
        this.lastNotificationTime = moment().utc();
      }).catch(() => {
        if (null !== this.intervalId) {
          clearInterval(this.intervalId);
        }
      });
    },
    goTo(notification) {
      if ('undefined' !== notification.iri) {
        this.$router.push(notification.iri);
      }
    },
    viewNotifications() {
      this.unreadNotifications = 0;
      UserPreferences.setMostRecentNotification(this.lastNotificationTime.unix());
    },
  },
};
</script>

<style scoped>

</style>
